




































































































































































































































































































.pageContol {
  padding: 0 10px;
}
.my-body {
  padding: 10px 0;
  justify-content: space-between;
  .body-top {
    height: 40%;
    display: flex;
    align-items: stretch;
    .panel {
      width: 18%;
      min-width: 200px;
      max-width: 300px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      :first-child.panel-item {
        margin-bottom: 20px;
      }
      .panel-item {
        color: #ffffff;
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.blue {
          background: #68acef;
        }
        &.green {
          background: #5ad3a2;
        }
        .tit {
          font-size: 16px;
        }
        .content {
          clear: both;
          .left {
            float: left;
            width: 50%;
            text-align: left;
            :first-child {
              font-size: 14px;
            }
            :last-child {
              font-size: 24px;
              line-height: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .right {
            float: right;
            width: 50%;
            text-align: right;
            :first-child {
              font-size: 14px;
            }
            :last-child {
              font-size: 24px;
              line-height: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .table {
      width: 82%;
      background: #fff;
      border-radius: 12px;
      padding: 20px;
      .tittab {
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        margin-bottom: 15px;
      }
      /deep/ .el-table td.el-table__cell,
      /deep/ .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      /deep/.el-table::before {
        height: 0;
      }
    }
  }
  .body-bottom {
    height: 58%;
    padding: 22px 20px;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .titbot {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 15px;
    }
    .chart {
      flex: 1;
    }
  }
}
