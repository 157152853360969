.pageContol {
  padding: 0 10px;
}
.my-body {
  padding: 10px 0;
  justify-content: space-between;
}
.my-body .body-top {
  height: 40%;
  display: flex;
  align-items: stretch;
}
.my-body .body-top .panel {
  width: 18%;
  min-width: 200px;
  max-width: 300px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}
.my-body .body-top .panel :first-child.panel-item {
  margin-bottom: 20px;
}
.my-body .body-top .panel .panel-item {
  color: #ffffff;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.my-body .body-top .panel .panel-item.blue {
  background: #68acef;
}
.my-body .body-top .panel .panel-item.green {
  background: #5ad3a2;
}
.my-body .body-top .panel .panel-item .tit {
  font-size: 16px;
}
.my-body .body-top .panel .panel-item .content {
  clear: both;
}
.my-body .body-top .panel .panel-item .content .left {
  float: left;
  width: 50%;
  text-align: left;
}
.my-body .body-top .panel .panel-item .content .left :first-child {
  font-size: 14px;
}
.my-body .body-top .panel .panel-item .content .left :last-child {
  font-size: 24px;
  line-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-body .body-top .panel .panel-item .content .right {
  float: right;
  width: 50%;
  text-align: right;
}
.my-body .body-top .panel .panel-item .content .right :first-child {
  font-size: 14px;
}
.my-body .body-top .panel .panel-item .content .right :last-child {
  font-size: 24px;
  line-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-body .body-top .table {
  width: 82%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}
.my-body .body-top .table .tittab {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 15px;
}
.my-body .body-top .table /deep/ .el-table td.el-table__cell,
.my-body .body-top .table /deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.my-body .body-top .table /deep/ .el-table::before {
  height: 0;
}
.my-body .body-bottom {
  height: 58%;
  padding: 22px 20px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.my-body .body-bottom .titbot {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 15px;
}
.my-body .body-bottom .chart {
  flex: 1;
}
